@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

/* src/index.css */

body {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f9fc;
  margin: 0;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Added padding for scrolling */
  min-height: 100vh;
  /* Custom scrollbar that is thin, subtle and round */
  scrollbar-width: thin;
  scrollbar-color: #ced4da #f7f9fc;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




body {
  font-family: 'Poppins', sans-serif;
  background-color: #f7f9fc;
  margin: 0;
  padding: 40px 0; /* Added padding for scrolling */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #ddd;
  padding: 40px;
  max-width: 800px; /* Increased width */
  width: 100%;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
}

h1 {
  font-weight: 600;
  margin-bottom: 30px;
}
.input-group {
  display: flex;
  align-items: center;
}

.input-group .form-control,
.input-group .btn {
  flex: 1 1;
  height: 38px; /* Ensure both elements have the same height */
}

.input-group .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px; /* Remove the gap between select and button */
}

.input-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

select, button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

button:hover, select:hover {
  background-color: #cbcbcb;
}

input[type="text"], input[type="color"], input[type="range"], select, button {
  font-family: 'Poppins', sans-serif;
}

.input-group input[type="text"], .input-group input[type="color"] {
  height: 38px; /* Ensure both inputs have the same height */
  padding: 6px 12px; /* Add padding */
  box-sizing: border-box; /* Include padding in height */
}

.input-group input[type="text"] {
  width: calc(50% - 10px); /* Adjusted width to keep them aligned */
}

.input-group input[type="color"] {
  width: calc(50% - 10px); /* Adjusted width to keep them aligned */
  margin-left: 10px;
  padding: 0; /* Remove extra padding from color input */
  border: 1px solid #ced4da; /* Match the border style */
  border-radius: 0.25rem; /* Match the border radius */
}

input[type="range"] {
  width: 100%;
  margin-top: 10px;
}


label {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.qr-code-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}


